<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <KTCard>
                    <template v-slot:title>
                        {{
                            operation != null ?
                                ( operation === 'add' ? 'Add Decor' : ( operation === 'detail' ? 'Decor Detail'
                                    : 'Edit Decor' ) ) : operationTitle
                        }}
                        <p><small>Manage Decor Packages and Offerings</small></p>
                    </template>
                    <template v-slot:toolbar>
                        <b-input-group-append>
                            <router-link to="/decors/create"
                                         v-if="globalPropertyId > 0 && $global.hasPermission('decorsstore')"
                            >
                                <b-button class="btn btn-primary font-weight-bolder"
                                          v-b-tooltip.hover :title="`Add New`"
                                          variant="success"><i class="fa fa-plus fa-sm"></i> Add New
                                </b-button>
                            </router-link>
                            <!--                            <b-button variant="info"-->
                            <!--                                      class="ml-2 filter-button"-->
                            <!--                                      v-b-tooltip.hover :title="`Filter`"-->
                            <!--                                      @click="filters.visible = !filters.visible">-->
                            <!--                                <i class="fa fa-filter fa-sm"></i> Filter-->
                            <!--                            </b-button>-->
                        </b-input-group-append>
                    </template>
                    <template v-slot:body>
                        <v-card>
                            <div class="decors-table">
                                <div class="w-100 mb-6">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="dataTables_length"><label>Show
                                                <b-form-select v-model="pagination.totalPerPage"
                                                               class="datatable-select"
                                                               :options="[10, 50, 100, 1000]"
                                                               size="sm"></b-form-select>
                                                entries</label></div>

                                        </div>
                                        <!--                                        <div class="col-sm-12 col-md-6 mt-2">-->
                                        <!--                                            <span v-for="(value,key) in filters"-->
                                        <!--                                                  :key="key">-->
                                        <!--                                                <span v-if="_.isArray(value) && key !== 'visible'">-->
                                        <!--                                                    <b-tag class="py-2 text-capitalize" v-if="value.length > 0"> {{$global.removeSymbols(key)}}-->
                                        <!--                                                        <i class="fa fa-times-circle ml-2"-->
                                        <!--                                                           @click="clearFilter(key, [])"></i>-->
                                        <!--                                                    </b-tag>-->
                                        <!--                                                </span>-->
                                        <!--                                                <span v-else>-->
                                        <!--                                                    <b-tag class="py-2"-->
                                        <!--                                                           v-if="value && key !== 'visible'"> <span-->
                                        <!--                                                        class="text-capitalize">{{$global.removeSymbols(key)}}</span>-->
                                        <!--                                                    <i class="fa fa-times-circle ml-2"-->
                                        <!--                                                       @click="clearFilter(key , null)"></i>-->
                                        <!--                                                    </b-tag>-->
                                        <!--                                                </span>-->
                                        <!--                                            </span>-->
                                        <!--                                        </div>-->
                                        <div class="col-sm-12 col-md-6">
                                            <div id="kt_datatable_filter" class="dataTables_filter"><label>Search:
                                                <b-form-input type="search"
                                                              class="form-control form-control-sm ml-2 w-25"
                                                              label="Search"
                                                              v-on:keyup.enter="handleSearch"
                                                              v-model="search"></b-form-input>
                                            </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <v-data-table
                                    responsive
                                    ref="table"
                                    :headers="columns"
                                    :items="dataSource"
                                    :loading="loading"
                                    :items-per-page="parseInt(pagination.totalPerPage)"
                                    hide-default-footer
                                    class="table table-head-custom table-vertical-center table-responsive"

                                >
                                    <template v-slot:item.id="record">
                                        {{ dataSource.length - record.index }}
                                    </template>
                                    <template v-slot:item.s_gst="record">
                                        {{ record.item.s_gst }}%
                                    </template>
                                    <template v-slot:item.c_gst="record">
                                        {{ record.item.c_gst }}%
                                    </template>
                                    <template v-slot:item.rate="record">
                                        {{ $global.numberToLocationString(record.item.rate) }}
                                    </template>
                                    <template v-slot:item.action="record" class="action-column">
                                        <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none"
                                                    no-caret>
                                            <template #button-content>
                                                <p class="btn btn-icon btn-light btn-hover-primary btn-sm">
                                                     <span
                                                         class="svg-icon svg-icon-md svg-icon-primary m-0">
                                                      <!--begin::Svg Icon-->
                                                      <inline-svg
                                                          src="/media/svg/icons/General/Settings-1.svg"
                                                          class="action-edit-button"
                                                      ></inline-svg>
                                                         <!--end::Svg Icon-->
                                                    </span>
                                                </p>
                                            </template>
                                            <b-dropdown-item href="#" @click="openImageUploadModel(record.item.id)">
                                                <span class="pt-1 pb-1">
                                                    <i class="fa fa-plus fa-sm mr-2"></i>
                                                    Add Decors Files
                                                </span>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                        <router-link :to="`decors/edit/${record.item.id}`">
                                            <a size="sm" class="btn btn-icon btn-light btn-hover-primary btn-sm ml-2"
                                               v-show="$global.hasPermission('hallsupdate')" href="javascript:;">
                                                <span
                                                    class="svg-icon svg-icon-md svg-icon-primary">
                                                  <!--begin::Svg Icon-->
                                                  <inline-svg
                                                      src="/media/svg/icons/Communication/Write.svg"
                                                      class="action-edit-button"
                                                  ></inline-svg>
                                                    <!--end::Svg Icon-->
                                                </span>
                                            </a>
                                        </router-link>
                                        <a size="sm" class="ml-2 btn btn-icon btn-light btn-hover-primary btn-sm"
                                           v-show="$global.hasPermission('hallsdestroy')"
                                           @click="handleDeleteOperation(record.item.id)">
                                            <span
                                                class="svg-icon svg-icon-md svg-icon-primary">
                                              <!--begin::Svg Icon-->
                                              <inline-svg
                                                  src="/media/svg/icons/General/Trash.svg" class="action-delete-button"
                                              ></inline-svg>
                                                <!--end::Svg Icon-->
                                            </span>
                                        </a>
                                    </template>
                                </v-data-table>
                                <div class="row">
                                    <div class="pagination-box">
                                        <b-pagination
                                            v-model="pagination.current"
                                            :total-rows="pagination.total"
                                            :per-page="pagination.totalPerPage"
                                            size="lg"
                                        ></b-pagination>

                                    </div>
                                </div><!-- /.pull-right -->
                            </div>
                        </v-card>
                    </template>
                </KTCard>

                <div class="filter-container">
                    <div id="filter_list"
                         ref="decor_list"
                         v-show="!operation &&filters.visible"
                         :class="filters.visible ? 'offcanvas offcanvas-right p-10 offcanvas-on' :'offcanvas offcanvas-right p-10'"
                    >
                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                            <b-row>
                                <b-col sm="12" class="text-right">
                                    <a
                                        href="javascript:;"
                                        @click="filters.visible = !filters.visible"
                                        class="btn btn-xs btn-icon btn-light btn-hover-primary text-right"
                                    >
                                        <i class="ki ki-close icon-xs text-muted"></i>
                                    </a>
                                </b-col>
                                <b-col sm="12">
                                    <b-form-group
                                        label="Name Of Decor"
                                        label-for="name">
                                        <b-form-input id="filter-name" v-model="filters.name"
                                                      class="mb-2"></b-form-input>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        label="Rate"
                                        label-for="rate">
                                        <b-form-input id="filter-rate" v-model="filters.rate"
                                                      class="mb-2"></b-form-input>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button @click="handleResetFilterClick()" size='sm' variant="info">
                                    Clear All
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </div>
                </div>
                <!-- /.filter-container -->
            </div>
        </div>
        <b-modal id="upload-image" hide-footer size="lg">
            <template #modal-title>
                Upload Image
            </template>
            <form @submit.prevent="handleSubmitFile" enctype="multipart/form-data">
                <b-row sm="12" lg="12">
                    <b-col sm="9">
                      <b-form-file
                        @change="onChangeFile"
                        id="file">
                      </b-form-file>
                    </b-col>
                    <b-col sm="3">
                        <button type="submit" class="btn btn-primary"> Add Image</button>
                    </b-col>
                </b-row>
            </form>
            <hr />
            <b-row>
                <b-col sm="3" v-for="(image,index) in decor_images" :key="index" class="mb-2">
                    <img :src="image.download_url" class="img-responsive mb-2 border" width="170" height="150">
                    <i class="fa fa-trash text-danger" @click="removeImage(image.id)"></i>
                </b-col>
            </b-row>
        </b-modal><!-- /.send-cc-email -->

    </div>
</template>

<script>
import { mapState } from 'vuex';
import KTCard from "@/view/content/Card.vue";
import ListingMixin from "@/assets/js/components/ListingMixin";
import ModuleMixin from "./module.mixin";

export default {
    mixins: [ListingMixin, ModuleMixin],
    data() {
        return {};
    },
    components: {
        KTCard,
    },
    computed: {
        ...mapState([
            'global',
        ]),
    },
};
</script>

<style scoped>
table {
    width: 100% !important;
}
</style>

